import { PaletteColorOptions as MUIPaletteColorOptions, PaletteOptions } from '@mui/material/styles';
import acerTokens from './tokens/acerTokens';
const tokens = acerTokens.light;

const createPaletteOptions = (): PaletteOptions => ({
  primary: palletteColorOptionsByName('primary'),
  secondary: palletteColorOptionsByName('secondary'),
  error: palletteColorOptionsByName('error'),
  warning: palletteColorOptionsByName('warning'),
  info: palletteColorOptionsByName('info'),
  success: palletteColorOptionsByName('success'),
  qualifiedSuccess: palletteColorOptionsByName('qualifiedSuccess'),
  risk: palletteColorOptionsByName('risk'),
 // common: COLORS.common,
  text:  {
    contrast: tokens.text.contrast.value,
    primary: tokens.text.primary.value,
    secondary: tokens.text.secondary.value,
    disabled: tokens.text.disabled.value,
    },
    textLight:  {
      contrast: tokens.text.light.contrast.value,
      primary: tokens.text.light.primary.value,
      secondary: tokens.text.light.secondary.value,
      disabled: tokens.text.light.disabled.value,
    },
    divider: tokens.divider.value,
    outlineBorder: tokens.outlineBorder.value.color,
    standardInputLine: tokens.standardInputLine.value.color,
    backdropOverlay:  tokens.backdropOverlay.value,
    action: {
    active: tokens.action.active.value,
    hover: tokens.action.hover.value,
    selected: tokens.action.selected.value,
    disabled: tokens.action.disabled.value,
    disabledBackground: tokens.action.disabledBackground.value,
    focus: tokens.action.focus.value,
  },
});
export default createPaletteOptions;

//interface PaletteColorOptions extends MUIPaletteColorOptions {  tint:string };


function palletteColorOptionsByName(name: keyof typeof tokens):MUIPaletteColorOptions{
  const token = tokens[name] as any;
  return {
    tint: token.tint.value,
    light: token.light.value,
    main: token.main.value,
    dark: token.dark.value,
    contrastText: token.contrast.value,
    foreground: token.foreground.value,
    helperText: token.helperText.value,
    mainHover:token.states.hover,
    selected:token.states.selected,
    focusVisible:token.states.focusVisible,
    outlinedBorder: token.states.outlinedBorder,
    // outlinedResting: 'rgba(136,60,173,0.08)',
    // outlinedHover: 'rgba(136,60,173,0.50)',
  } as MUIPaletteColorOptions ;
}

