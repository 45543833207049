import React, { ReactNode } from 'react';
import { Link } from "react-router-dom";

import MUIIcon, { IconProps } from '@mui/material/Icon';
import List from "@mui/material/List";
import Fade from "@mui/material/Fade";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";



import { styled } from '@mui/system';
import createDcpUiTheme from "../theme/createTheme";
const theme = createDcpUiTheme();


const StyledIcon = styled(MUIIcon)(({ theme }) => ({
  '&.material-symbols-outlined': {
    //  custom styles here
  },
}));

const useStyles = {
  iconButton: {
    color: theme.palette.textLight.contrast,
    display: 'flex', flexDirection: 'column',
    fontSize: 14, lineHeight: 1.1,
    padding: 2,
  },
  iconButtonIcon: {
    fontFamily: 'Material Symbols Outlined', fontSize: 32,
    color: theme.palette.textLight.contrast,
    mb: "3px"
  },
};


const Icon = (props: any) => {
  // set the baseClassName prop here
  return <StyledIcon baseClassName="material-symbols-outlined" {...props} />;
};

export default Icon;

interface LabelledIconProps extends IconButtonProps { key?: string | number, active?: boolean, gap?: string, scale?: string, backgroundColor?: string, to: string, label?: string, icon?: ReactNode };

export const LabelledIcon = (props: any) => {
  let sx: any = useStyles.iconButton;
  if (props.gap === 'before') sx = { ...sx, mt: 'auto' };
  return <Fade in timeout={600}><Tooltip title={props.title} arrow placement="right">
    <Fade in={true} timeout={600}>
      <Box sx={{ borderRadius: 4, margin: 1, mt: (props.gap === 'before') ? 'auto' : '0', backgroundColor: props.backgroundColor }}>
        <Fade in timeout={600}>
          <IconButton sx={{ ...useStyles.iconButton, color: props.color, borderRadius: 0 }} component={Link} id={props.id} to={props.to}>
            <Icon sx={{ ...useStyles.iconButtonIcon, color: props.color, transform: `scale(${props.scale || 1})` }}>{props.icon}</Icon>
            {props.label}
          </IconButton>
        </Fade>
      </Box></Fade>
  </Tooltip></Fade>
}

// export const LabelledIcon = (props: any) => {
//   let sx: any = useStyles.iconButton;
//   if (props.gap === 'before') sx = { ...sx, mt: 'auto' };
//   return       <Fade in timeout={600}><Tooltip title={props.title} arrow placement="right">
//         <Fade in={ props.active} timeout={600}>
//       <Box sx={{ width: '100%', height: '82', mt: (props.gap === 'before') ? 'auto' : '0', backgroundColor: props.active ? theme.palette.primary.main : '#0000' }}>
//       <Fade in timeout={600}>
//         <IconButton key={props.key} sx={sx} component={Link} id={props.id} to={props.to}>
//           <Icon sx={{...useStyles.iconButtonIcon, transform: `scale(${props.scale || 1})`}}>{props.icon}</Icon>
//           {props.label}
//         </IconButton>
//       </Fade>
//     </Box></Fade>
//   </Tooltip></Fade>;
// }

// import EntityCarerIcon from '/icons'
// const CarerIcon = (props:any)=>{
//   <Icon><img src={EntityCarerIcon}/></Icon>
// }