import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://ceracare.co.uk/">Cera Care</Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer(props:any){return Copyright(props);}

export default Footer;