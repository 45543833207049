import React, {useContext} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chip from '@mui/material/Chip';
import Footer from "../PageFooter/PageFooter";

import {layoutContext} from '../../App/App';




export default function DemoPage(props:any) {
  const  layout = useContext(layoutContext);
  const setRail =()=> {layout.setLayoutMode('rail')}
  const setTop =()=> {layout.setLayoutMode('topBar')}

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 'auto',
              }}
            >
              <h1>Inbox</h1>
            <h2>Prototype actions</h2>
            <Grid spacing={2}>
              <h3>{layout.layoutMode}</h3><br/>
              <Chip label="rail" variant="outlined" onClick={setRail}></Chip>&#160;            
              <Chip label="topBar" variant="outlined" onClick={setTop}></Chip>
            </Grid>
            </Paper>
          </Grid>


       </Grid>
        <Footer sx={{ pt: 4 }} />
      </Container>
    );
  }
  