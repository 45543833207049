import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import TextField from "@mui/material/TextField";
import { Chip, IconButton, InputAdornment, Typography } from "@mui/material";
import Icon from "../../Components/Icon";
import { Props } from "recharts/types/container/Surface";

// Generate Order Data
function createData(
  id: number,
  name: string,
  status: string,
  notes: string,
  amount: number
) {
  return { id, name, status, notes, amount };
}

const rows = [
  createData(
    0,
    "Elvis Presley",
    "Active, compliant",
    "VISA ⠀•••• 3719",
    312.44
  ),
  createData(
    1,
    "Paul McCartney",
    "Active, compliant",
    "VISA ⠀•••• 2574",
    866.99
  ),
  createData(
    2,
    "Tom Scholz",
    "Active, compliant",
    "MC ⠀•••• 1253",
    100.81
  ),
  createData(
    3,
    "Michael Jackson",
    "Active, compliant",
    "AMEX ⠀•••• 2000",
    654.39
  ),
  createData(
    4,
    "Bruce Springsteen",
    "Active, compliant",
    "VISA ⠀•••• 5919",
    212.79
  ),
];

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export function TaskList() {
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell><Link>{row.name}</Link></TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
 
function TaskFilter() {
  const handleClick = () => {};
  return (
    <Grid sx={{ p: 1, display: "flex", flexDirection: "row", gap: 1 }}>
      <Chip
        label="Recent"
        variant="filled"
        color="info"
        onClick={handleClick}
      />
      <Chip
        label="Clients"
        variant="outlined"
        color="info"
        onClick={handleClick}
      />
      <Chip
        label="Carers"
        variant="outlined"
        color="info"
        onClick={handleClick}
      />
      <Chip label="All" variant="outlined" color="info" onClick={handleClick} />
    </Grid>
  );
}


interface SearchProps {title:string};

export default function Search({title}:SearchProps) {
  return (
    <React.Fragment>
      <Paper
        sx={{
          p: 2,
          mb: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2>{title}</h2>
        <TaskFilter />
        <br />
        <TaskList />
        <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
          See more tasks
        </Link>
      </Paper>
    </React.Fragment>
  );
}
