import React, { useState, createContext, ReactNode } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import { styled, createTheme, ThemeProvider, useTheme, Theme } from "@mui/material/styles";

import Fab from "@mui/material/Fab";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

import TopBar from "../Components/TopBar";
import DrawerMenuLHS from "../Components/DrawerLHSMenu";

import NavigationRail from "../Components/NavigationRail";

import DemoPage from "../Content/Pages/ContentDemoPage";
import HomePage from "../Content/Pages/Home";
import NotFoundPage from "../Content/Pages/404";
import ClientsPage from "../Content/Pages/Clients";
import ClientPage from "../Content/Pages/Client";
import CarersPage from "../Content/Pages/Carers";
import CarerPage from "../Content/Pages/Carer";
import VisitsPage from "../Content/Pages/Visits";
import InboxPage from "../Content/Pages/Inbox";
import AlertsPage from "../Content/Pages/Alerts";
import ProfilePage from "../Content/Pages/Profile";
import SearchPage from "../Content/Pages/Search";
import FinOpsPage from "../Content/Pages/Finops";

import { TextField, Typography } from "@mui/material";

import createDcpUiTheme from "../theme/createTheme";
import TopStrip from '../Components/TopStrip';
const theme = createDcpUiTheme();

export const railColours = {
  black: {
    background: theme.palette.common.black,
    text: theme.palette.textLight.primary
  },
  white: {
    background: theme.palette.common.white,
    text: theme.palette.primary.main,
    activeBackground: theme.palette.primary.light,
    activeText: theme.palette.primary.contrastText
  },
  pale: {
    background: (theme.palette.primary as any).tint,
    text: theme.palette.text.primary,
    activeBackground: theme.palette.primary.light,
    activeText: theme.palette.primary.contrastText
  },
  primary: {
    background: theme.palette.primary.light,
    text: theme.palette.textLight.primary
  },
}.white;

export const layoutContext = createContext({ layoutMode: 'rail', setLayoutMode: null as any });


const toolbarRelativeStyles = (property: any, theme: any, modifier = (value: any) => value) =>
  Object.keys(theme.mixins.toolbar).reduce((style, key) => {
    const value = theme.mixins.toolbar[key];
    if (key === "minHeight") {
      return { ...style, [property]: modifier(value) };
    }
    if (value.minHeight !== undefined) {
      return { ...style, [key]: { [property]: modifier(value.minHeight) } };
    }
    return style;
  }, {});


const useStyles = ({
  root: {
    display: "flex"
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  drawerPaper: {
    width: 240
  },
  drawerToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  rail: {
    ...toolbarRelativeStyles("top", theme)
  },
  content: {
    ...toolbarRelativeStyles(
      "paddingTop",
      theme,
      (value) => value + theme.spacing(2)
    ),
    paddingLeft: 72 + theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
});

function Layout(props: { menu?: boolean | null; children: ReactNode }) {
  const [layoutMode, setLayoutMode] = useState('rail'); // topBar
  const propsWithDefaults = {
    ...{ menu: true, layoutMode },
    ...props,
  };
  return <layoutContext.Provider value={{ layoutMode, setLayoutMode }}>{(layoutMode == 'topBar') ? TopLeft({ ...propsWithDefaults, layoutMode }) : RailLayout({ ...propsWithDefaults, layoutMode })}</layoutContext.Provider>;
}

function Rail() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  return <NavigationRail />
}


function IntercomFAB() {
  return <Fab
    color="info"
    size="large"
    sx={{ zIndex: 100000, position: "fixed", bottom: 100, left: 24 }}
    component={Link}
    to={"/Inbox"}
  >
    <Icon baseClassName="material-symbols-outlined">chat_bubble</Icon>
  </Fab>
}


function RailLayout(props: any) {
  return <Box sx={{ display: "", overflow: "hidden" }}>
    <CssBaseline />
    <TopStrip></TopStrip>
    <IntercomFAB />
    <Box display="flex">
      <Rail></Rail>
      {props.menu ? <DrawerMenuLHS /> : null}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "calc(100vh)",
          overflow: "auto",
          marginTop: "0",
        }}
      >
        {props.children}


      </Box>
    </Box>
  </Box>;
}

function RailLayoutNoMenu(props: any) {
  return <Box sx={{ display: "flex", overflow: "hidden" }}>
    <CssBaseline />
    <Rail></Rail>
    {/* <DrawerMenuLHS /> THIS IS THE ONLY DIFFERENCE -- USE A prop instead of two funcs*/}
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh)",
        overflow: "auto",
        marginTop: "0",
      }}
    >
      {props.children}

      <Fab
        color="info"
        size="large"
        sx={{ position: "fixed", bottom: 48, left: 48 }}
        component={Link}
        to={"/Inbox"}
      >
        <Icon baseClassName="material-symbols-outlined">chat_bubble</Icon>
      </Fab>
    </Box>
  </Box>;
}

function TopLeft(props: any) {
  return <Box sx={{ display: "flex", overflow: "hidden" }}>
    <CssBaseline />
    <TopBar />
    {props.menu ? <DrawerMenuLHS sx={{ top: 64 }} /> : null}

    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "auto",
        marginTop: "64px",
      }}
    >
      {props.children}

      <Fab
        color="info"
        size="large"
        sx={{ position: "fixed", bottom: 48, left: 48 }}
        component={Link}
        to={"/Inbox"}
      >
        <Icon baseClassName="material-symbols-outlined">chat_bubble</Icon>
      </Fab>
    </Box>
  </Box>;
}

export default function App() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/"
            element={<DemoPage />}
            errorElement={<DemoPage />}
          />
          <Route path="Home" element={<Layout><HomePage /></Layout>} />
          <Route path="Clients" element={<Layout><a href="/Clients/Client"><img src="/screenShots/Clients.png" /></a></Layout>} />
          <Route path="Clients/Client" element={<Layout>1<ClientPage /></Layout>} />
          <Route path="Carers" element={<Layout><a href="/Carers/Carer"><img src="/screenShots/Carers.png" /></a></Layout>} />
          <Route path="Carers/Carer" element={<Layout><CarerPage /></Layout>} />
          <Route path="Clients/Search" element={<Layout><a href="/Client"><img src="/screenShots/Clients.png" /></a></Layout>} />
          <Route path="Clients/Alerts" element={<Layout><AlertsPage /></Layout>} />
          <Route path="Visits" element={<Layout menu={true}><img src="/screenShots/Visits2.png" /></Layout>} />
          <Route path="Visits/Roster" element={<Layout menu={false}><img src="/screenShots/Roster.png" /></Layout>} />
          <Route path="Finance" element={<Layout><FinOpsPage /></Layout>} />
          <Route path="Branch" element={<Layout><FinOpsPage /></Layout>} />
          <Route path="HR" element={<Layout><FinOpsPage /></Layout>} />

          <Route path="Finance/AdditionalsInvoices" element={<Layout menu={true}><img src="/screenShots/FinanceAdditionalsInvoices.png" /></Layout>} />
          <Route path="Finance/AlertsInvoices" element={<Layout menu={true}><img src="/screenShots/FinanceAlertsInvoices.png" /></Layout>} />
          <Route path="Finance/Import" element={<Layout menu={true}><img src="/screenShots/FinanceImport.png" /></Layout>} />
          <Route path="Finance/InvoicesProcessing" element={<Layout menu={true}><img src="/screenShots/FinanceInvoicesProcessing.png" /></Layout>} />
          <Route path="Finance/PaymentsStatus" element={<Layout menu={true}><img src="/screenShots/FinancePaymentsStatus.png" /></Layout>} />
          <Route path="Finance/PaymentsProcessing" element={<Layout menu={true}><img src="/screenShots/FinancePaymentsProcessing.png" /></Layout>} />
          <Route path="Finance/RecordsPayments" element={<Layout menu={true}><img src="/screenShots/FinanceRecordsPayments.png" /></Layout>} />
          <Route path="Finance/VisitsToInvoice" element={<Layout menu={true}><img src="/screenShots/FinanceVisitsToInvoice.png" /></Layout>} />
          <Route path="Finance/AdditionalsPayments" element={<Layout menu={true}><img src="/screenShots/FinanceAdditionalsPayments.png" /></Layout>} />
          <Route path="Finance/InvoicesModels" element={<Layout menu={true}><img src="/screenShots/FinanceInvoicesModels.png" /></Layout>} />
          <Route path="Finance/InvoicesStatus" element={<Layout menu={true}><img src="/screenShots/FinanceInvoicesStatus.png" /></Layout>} />
          <Route path="Finance/PaymentsModels" element={<Layout menu={true}><img src="/screenShots/FinancePaymentsModels.png" /></Layout>} />
          <Route path="Finance/RecordsInvoices" element={<Layout menu={true}><img src="/screenShots/FinanceRecordsInvoices.png" /></Layout>} />
          <Route path="Finance/VisitsScheduled" element={<Layout menu={true}><img src="/screenShots/FinanceVisitsScheduled.png" /></Layout>} />
          <Route path="Finance/VisitsScheduled" element={<Layout menu={true}><img src="/screenShots/FinanceVisitsScheduled.png" /></Layout>} />
          <Route path="Finance/VisitsToPay" element={<Layout menu={true}><img src="/screenShots/FinanceVisitsToPay.png" /></Layout>} />


          <Route path="Inbox" element={<Layout><InboxPage /></Layout>} />
          <Route path="Alerts" element={<Layout><AlertsPage /></Layout>} />
          <Route path="Tasks" element={<Layout><NotFoundPage /></Layout>} />
          <Route path="Search" element={<Layout><SearchPage /></Layout>} />
          <Route path="Profile" element={<Layout><ProfilePage /></Layout>} />
          <Route path="Records" element={<Layout><NotFoundPage /></Layout>} />
          <Route path="Reports" element={<Layout><NotFoundPage /></Layout>} />
          <Route path="Audit" element={<Layout><NotFoundPage /></Layout>} />
          <Route path="Units" element={<Layout><NotFoundPage /></Layout>} />
          <Route path="Settings" element={<Layout><NotFoundPage /></Layout>} />
        </Routes>

      </ThemeProvider>
    </Router >
  );
}















