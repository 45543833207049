import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Chart from "../components/Chart";
import Deposits from "../components/Deposits";
import Orders from "../components/Orders";
import Footer from "../PageFooter/PageFooter";
import Search from "../components/Search";

export default function DemoPage() {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Search title="Search DCP"/>
      </Container>
    );
  }
  