import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  Route,
  Routes,
  Link,
} from "react-router-dom";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
//import Icon from '@mui/material/Icon'
import Icon from './Icon';
//
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MessagesIcon from "@mui/icons-material/Message";

import menuHierarchy from "../MetaData/menuData";
import { Props } from "recharts/types/container/Surface";

import findBestMatchForRoute from "../utilities/router";

export const drawerWidth: number = 240;



const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "calc(100vh - 64px)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));


function menuName(routeName:string) {
  let route: keyof typeof menuHierarchy=routeName.replace('topandleft/','') as keyof typeof menuHierarchy
  if (!(route in menuHierarchy)) route = "/";
  return menuHierarchy[route].label;
}


function getMenu(locationPath : string){
  let route: keyof typeof menuHierarchy=locationPath.replace('topandleft/','') as keyof typeof menuHierarchy
  if (!(route in menuHierarchy)) route = "/";
  route = findBestMatchForRoute(locationPath, menuHierarchy) as keyof typeof menuHierarchy;
  const menu = menuHierarchy[route];
  return {menu,route,locationPath};
}

/**
 * Generates a list of menu items for a given route name.
 *
 * This function takes a route name, extracts a corresponding key from the menuHierarchy,
 * and generates a list of menu items based on the menu configuration associated with that key.
 * It handles the rendering of both divider and list items with icons and labels.
 *
 * @param routeName - The route name for which to generate menu items.
 * @returns A React component that renders the menu items for the specified route.
 */
function menuItems(routeName:string) {
  // let route: keyof typeof menuHierarchy=routeName.replace('topandleft/','') as keyof typeof menuHierarchy
  // if (!(route in menuHierarchy)) route = "/";
  // route = findBestMatchForRoute(routeName, menuHierarchy) as keyof typeof menuHierarchy;
  // const menu = menuHierarchy[route];
  const menu = getMenu(routeName).menu;
  return (
    <React.Fragment>
      {menu.menu.map((m:any, i:number) =>
        m.type.toLowerCase() == "divider" ? (
          <Divider key={i} />
        ) : (
          <ListItem button key={i} component={Link} to={m.to?m.to:'/'}>
            <ListItemIcon>
              <Icon>{m.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={m.label} />
          </ListItem>
        )
      )}
    </React.Fragment>
  );
}

interface BackButtonProps  {route:string; to?:string;};

function BackButton(props:BackButtonProps){
  if (!(props.route in menuHierarchy)) return;
  const menu =menuHierarchy[props.route as keyof typeof menuHierarchy];
  if (!menu) return;
  if (menu.back) return <Link to={props.to ||'/'}><Typography><Icon>arrow_back</Icon> back </Typography></Link>;
  return <Typography></Typography>;
}

export default function DrawerMenuLHS(props:any) {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const location = useLocation();
  const {menu,route:string,locationPath} = getMenu(location.pathname);
 

  useEffect(() => {
    // This code will run when the route changes
    // You can update your drawer's content or perform any other necessary actions here

    console.log(`Route changed to: ${route} from ${locationPath}`);
  }, [location.pathname]);

  const route = location.pathname as any;
  return (
    <Drawer variant="permanent" open={open}>
      <BackButton route={route} to={menu.back||'/'}></BackButton>
      <Toolbar 
        color="black"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: [1],
        }}
      >
        <Tooltip title={ `Path = ${location.pathname}`} arrow  placement="right">
          <Typography variant="h5">{menuName(route)}</Typography></Tooltip>
        {/* <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton> */}
      </Toolbar>

      <Divider />

      <List component="nav">
        {menuItems(route)}
        <Divider sx={{ my: 1 }} />
      </List>
      {/* <Typography variant="body2">{route}</Typography> */}
    </Drawer>
  );
}
