import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from './Icon';

import { LabelledIcon } from './Icon';
import menuHierarchy, { appsMenu as originalAppsMenu, AppsMenu, appsMenuKeyType, makeMenuItemActive, railMenu } from "../MetaData/menuData";
import createDcpUiTheme from "../theme/createTheme";
import { railColours } from '../App/App';
import findBestMatchForRoute from "../utilities/router";
const theme = createDcpUiTheme();
let appsMenu = originalAppsMenu;
let id = 0;

export default function buildAppsMenu(subMenu: [any]) { // strip out un-pinned ones
    return buildAppsMenuList(subMenu); //(subMenu.length<10)? buildAppsMenuList(subMenu): buildAppsMenuGrid(subMenu);
}

function buildAppsMenuList(subMenu: [any]) { // strip out un-pinned ones
    return <List>
        {
            Object.values(subMenu)
                .filter((app) => !app.pinned)
                .map((app, index) => {
                    return <ListItemButton color={'white'} key={index} id={'RailSubMenuGridItem_' + (id++)} 
                    component={Link} to={app.to}>
                        <ListItemIcon><Icon sx={{ color: '#ffffff' }}>{app.icon}</Icon></ListItemIcon>
                        <ListItemText primary={app.label} secondary={app.description}
                            secondaryTypographyProps={{ color: theme.palette.textLight.secondary }}
                        />
                       
                    </ListItemButton>
                }
                )}
    </List>;
}

function buildAppsMenuGrid(subMenu: [any]) { // strip out un-pinned ones
    return <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ height: '100%', width: '100%' }}>
        {
            Object.values(subMenu)
                .filter((app) => !app.pinned)
                .map((app, index) => {
                    return <Grid xs={4} item key={index} id={'RailSubMenuGridItem_' + (id++)}>
                        <LabelledIcon
                            id={'RailSubMenuLabelledIcon_' + (id++)}
                            key={id}
                            to={app.to}
                            icon={app.icon}
                            label={app.label}
                            gap={app.gap}
                            active={app.active}
                        /></Grid>
                }
                )}
    </Grid>;
}

function AppIcon(index: number, app: any): ReactNode {
    return
    <Grid xs={4} item key={index} id={'RailSubMenuGridItem_' + (id++)}>
        <LabelledIcon
            id={'RailSubMenuLabelledIcon_' + (id++)}
            to={app.to}
            icon={app.icon}
            label={app.label}
            gap={app.gap}
            active={app.active}
        /></Grid>
}