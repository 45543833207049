import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ListSubheader from '@mui/material/ListSubheader';
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Link } from "react-router-dom";
import CeraBrandIcon from "@mui/icons-material/AddBox";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Box, FormControl, MenuItem, Select, Tab, Tabs } from "@mui/material";
import createDcpUiTheme from "../theme/createTheme";

import { railColours } from '../App/App';

// Create a theme using the custom function
const theme = createDcpUiTheme();

// Define additional props for the custom AppBar
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

// Custom styled AppBar component
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  height: 32,
}));



// Component representing brand and branch information
function BrandBranch() {
  return (
    <React.Fragment>
      <Link to="/Home">
        <CeraBrandIcon sx={{ width:32, height:32, mt: 0, color: railColours.text }} />
      </Link>
      {/* Branch selection dropdown */}
      <FormControl variant="standard" sx={{ m: 0, ml: 4, minWidth: 220 }} size="small">
        <Select
          labelId="demo-main-select-label"
          id="demo-main-select"
          value={20}
          sx={{
            fontSize: 16.5,
            color: railColours.text,
            "& MuiSelect-root": { fontSize: 10 },
            "& .MuiSvgIcon-root": { color: railColours.text},
          }}
        >
          <ListSubheader>Your branches</ListSubheader>
          <MenuItem value={10}>Wandsworth</MenuItem>
          <MenuItem value={20}>Hammersmith</MenuItem>
          <MenuItem value={30}>Shepherd's Bush</MenuItem>
          <ListSubheader>Admin</ListSubheader>
          <MenuItem value={40}>Training branch</MenuItem>
          <MenuItem value={50}>Request access to another branch</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
}


// Custom styled search container
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(railColours.text, 0.15),
  '&:hover': {
    backgroundColor: alpha(railColours.text, 0.25),
  },
  marginLeft: 0,
  width: '50%',
  height: "24px",
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

// Custom styled search icon wrapper
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// Custom styled input base for search
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0, 1, 0, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(30vw )',
      '&:focus': {
        width: 'calc(60vw )',
      },
    },
  },
}));

// Component for the top strip of the application
function TopStrip() {
  const [open, setOpen] = React.useState(true);
  const [value, handleChange] = React.useState(true);

  // Function to toggle the drawer
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box position="static" sx={{ backgroundColor: railColours.background, flexGrow: 1, height: 48, width: '100%', display: 'flex', justifyContent: 'space-between', padding: 1 }}>
      <Box sx={{ ml:3.25 }}> {/* Brand and branch information */}
        <BrandBranch />
      </Box>
      <Search> {/* Search bar */}
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search DCP…"
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      {/* User avatar */}
      <Link to="/Profile">
        <Box sx={{ display: 'inline', verticalAlign: 'middle' }}>
        <Button variant="text" size="small"  sx={{color:railColours.text,width:180}}><Typography variant="body2" sx={{ display: 'inline', width: '80%', verticalAlign: 'middle', pr: 1 }}>Andrew Peacock</Typography>
        <Box sx={{ display: "inline-block" }}>
          <Avatar sx={{ width: 24, height: 24, color: theme.palette.text.contrast }}><Typography variant="overline">AP</Typography></Avatar>
        </Box></Button>
      </Box>
      </Link>
    </Box>
  );
}

export default TopStrip;
