import React, { ReactNode, useEffect } from "react";
import {
    BrowserRouter as Router,
    useLocation,
    Route,
    Routes,
    Link,
} from "react-router-dom";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Fade from "@mui/material/Fade";
import Zoom from "@mui/material/Zoom";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
//
import Icon from './Icon';
import CeraBrandIcon from "@mui/icons-material/AddBox";
//
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MessagesIcon from "@mui/icons-material/Message";

import { LabelledIcon } from '../Components/Icon';
import menuHierarchy, { appsMenu as originalAppsMenu, AppsMenu, appsMenuKeyType, makeMenuItemActive, railMenu } from "../MetaData/menuData";
import createDcpUiTheme from "../theme/createTheme";
import { railColours } from '../App/App';
import findBestMatchForRoute from "../utilities/router";
import buildAppsMenu from './appMenuComponents'


const theme = createDcpUiTheme();
let appsMenu = originalAppsMenu;
export const drawerWidth: number = 96;


const useStyles = {
    button: {
        "&.active": {
            background: 'black',
        },
    }
};




const DrawerElement = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        backgroundColor: railColours.background,
        color: railColours.text,
        position: "relative",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(8),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(8),
            },
        }),
    },
}));


function railMenu2(appsMenuParam: AppsMenu) {
    let railMenuData: { [key: string]: any } = {};
    const appSubmenu: { [key: string]: any } = {};
    for (const keyAny in appsMenuParam) {
        const key = keyAny as appsMenuKeyType;
        const appApp: (typeof appsMenuParam['/Apps']) = appsMenuParam['/Apps'];
        const v = appsMenuParam[key];
        if (!v.pinned) { appSubmenu[key] = appsMenuParam[key]; }
    };
    appsMenuParam['/Apps'].subMenu = appSubmenu;
    for (const key in appsMenuParam) {
        const v = appsMenuParam[key as appsMenuKeyType]; if (v.pinned || v.active) railMenuData[key] = (appsMenuParam[key as appsMenuKeyType]);
    }
    return railMenuData;
}


export default function NavigationRail() {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const location = useLocation();
    const route = findBestMatchForRoute(location.pathname, menuHierarchy) as keyof typeof menuHierarchy;
    let id = 0;

    makeMenuItemActive(appsMenu, route); //location.pathname)
    //if (location.pathname in appsMenu) appsMenu[location.pathname].pinned=true;
    let railMenuApps = railMenu(appsMenu);

    useEffect(() => {
        // This code will run when the route changes
        // You can update your drawer's content or perform any other necessary actions here
        // console.log(`Route changed to: ${location.pathname}`);
    }, [location.pathname]);

    return (
        <DrawerElement variant="permanent" open={open} id="NavigationRailDrawerElement">
            <List component="nav" sx={{
                transition: 'all 1000', height: 'calc(100vh - 40px)',
                width: '100%', display: 'flex', flexDirection: 'column'
            }}>
                {(Object.values(appsMenu) as any).map((app: any, index: Number) =>
                    <Zoom appear unmountOnExit in={(app.pinned || app.active)} timeout={1500} key={index as any}>
                        <ListItem key={index as any}>
                            <LabelledIcon
                                id={'RailButton_' + (id++)}
                                key={index}
                                to={app.to}
                                icon={app.icon}
                                label={app.label}
                                gap={app.gap}
                                active={app.active}
                                backgroundColor={app.active ? railColours.activeBackground : 'transparent'}
                                color={app.active ? railColours.activeText : railColours.text}
                                title={app.subMenu ? buildAppsMenu(app.subMenu as [any]) : (app.tooltip || (app.label + ' App'))} />
                        </ListItem></Zoom>
                )}
            </List>
            <Divider />
        </DrawerElement>

    );
}
