/**
 * Finds the best matching key in the provided menuHierarchy for a given route string.
 *
 * This function iteratively removes parts from the end of the route string and checks if
 * a matching key exists in the menuHierarchy. It returns the best match found or a default
 * value ('/') if no match is found.
 *
 * @param route - The route string to find a match for.
 * @param menuHierarchy - The menu hierarchy as a record of key-value pairs.
 * @returns The best matching key from menuHierarchy or '/' if no match is is found.
 */
export default function findBestMatchForRoute(route: string, menuHierarchy: Record<string, any>): string {
  const routeParts = route.split('/');
  for (const _ of routeParts) if (routeParts.join('/') in menuHierarchy) return routeParts.join('/'); else routeParts.pop();
  return '/';
}