import * as React from 'react'
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Icon from "../Components/Icon";


import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CeraBrandIcon from "@mui/icons-material/AddBox";
import createDcpUiTheme from "../theme/createTheme";
const theme = createDcpUiTheme();
import CarerIcon from '../AcerIcons/CarerIcon';
import ClientIcon from '../AcerIcons/ClientIcon';

type AppsMenuItem = {
  type: 'App' | 'Divider';
  active: boolean | null;
  label: string | React.ReactNode;
  description?: string | null; // Description for the app (add your description here)
  icon: string | React.ReactNode;
  to: string;
  tooltip: string | React.ReactNode;
  gap?: string | null;
  pinned: boolean;
  subMenu?: Record<string, any> | null;
  back?: string;
  menu?: any;
  disabled?: boolean;
};

export type AppsMenu = Record<string, AppsMenuItem>;

export type appsMenuKeyType = keyof typeof appsMenu;



const ClientIconStyled = <ClientIcon sx={{ transform: `scale(1.333)` }} />;
const CarerIconStyled = <CarerIcon sx={{ transform: `scale(1.333)` }} />;
const newLocal = <Icon>Apps</Icon>;
export let appsMenu: AppsMenu = {
  "/Clients": {
    type: "App",
    active: false,
    label: "Clients",
    description: "Manage your clients",
    icon: ClientIconStyled,
    to: "/Clients",
    tooltip: "Clients app",
    gap: null,
    pinned: true,
    menu: [
      {
        type: "link",
        icon: "Search",
        label: "Find a client",
        to: "/Clients/Search",
      },
      {
        type: "link",
        icon: "Add",
        label: "Add New Client",
        to: "/Clients/Alerts",
      },
      { type: "divider" },
      {
        type: "link",
        icon: "Tasks",
        label: "All Actions",
        to: "/Clients/Alerts",
      },
      {
        type: "link",
        icon: "Notifications",
        label: "EMARS alerts",
        to: "/Clients/Alerts",
      },
      {
        type: "link",
        icon: "Tasks",
        label: "Checklists",
        to: "/Clients/Alerts",
      },
    ],
  },
  "/Visits": {
    type: "App",
    active: false,
    label: "Visits",
    description: "View and manage visits",
    icon: "event",
    to: "/Visits",
    tooltip: "All active, pending and recent visits",
    gap: null,
    pinned: true,
    menu: [
      { type: "link", icon: "Event", label: "Visits", to: "/Visits" },
      {
        type: "link",
        icon: "Calendar_Today",
        label: "Roster",
        to: "/Visits/Roster",
      },
      { type: "divider" },
      { type: "link", icon: "Star", label: "Runs", to: "/" },
      { type: "link", icon: "Star", label: "Shifts", to: "/" },
      { type: "link", icon: "Settings", label: "Shift Categories", to: "/" },
    ],
  },
  "/Carers": {
    type: "App",
    active: false,
    label: "Carers",
    description: "Manage carers",
    icon: CarerIconStyled,
    to: "/Carers",
    tooltip: "Carers",
    gap: null,
    pinned: true,
    menu: [
      {
        type: "link",
        icon: "Search",
        label: "Find a Carer",
        to: "/Carer/Search",
      },
      {
        type: "link",
        icon: "Add",
        label: "Add New Carer",
        to: "/Clients/Alerts",
      },
      { type: "divider" },
      { type: "link", icon: "Person", label: "All Carers", to: "/" },
      { type: "link", icon: "Map", label: "Map", to: "/" },
      { type: "link", icon: "Verified", label: "Compliance", to: "/" },
      { type: "divider" },
      { type: "link", icon: "Summarize", label: "Reports", to: "/" },
    ],
  },
  "/Finance": {
    type: "App",
    active: false,
    label: "Finance",
    description: "Finance workflows",
    icon: "Dashboard",
    to: "/Finance",
    tooltip: "Finance",
    pinned: false,
    menu: [
      {
        type: "link",
        icon: "Notifications",
        label: "Alerts",
        to: "/Finance/AlertsInvoices",
      },
      {
        type: "link",
        icon: "Search",
        label: "Visits",
        to: "/Finance/VisitsScheduled",
      },
      { type: "divider" },
      {
        type: "link",
        icon: "Note_stack_add",
        label: "Additionals",
        to: "/Finance/AdditionalsInvoices",
      },
      {
        type: "link",
        icon: "StarFilled",
        label: "Invoices",
        to: "/Finance/InvoicesStatus",
      },
      {
        type: "link",
        icon: "StarFilled",
        label: "Payments",
        to: "/Finance/PaymentsStatus",
      },
      { type: "divider" },
      {
        type: "link",
        icon: "folder_open",
        label: "Invoice Files",
        to: "/Finance/RecordsInvoices",
      },
      {
        type: "link",
        icon: "StarFilled",
        label: "Payment Files",
        to: "/Finance/RecordsPayments",
      },
      { type: "divider" },
      {
        type: "link",
        icon: "Upload_file",
        label: "Import",
        to: "/Finance/Import",
      },
    ],
  },
  "/Branch": {
    type: "App",
    active: false,
    label: "Branch",
    description: "Branch management workflows and data",
    icon: "Dashboard",
    to: "/Branch",
    tooltip: "Branch Finance",
    pinned: false,
    menu: [
      {
        type: "link",
        icon: "Notifications",
        label: "Alerts",
        to: "/Finance/AlertsInvoices",
      },
      {
        type: "link",
        icon: "Search",
        label: "Visits",
        to: "/Finance/VisitsScheduled",
      },
      { type: "divider" },
      {
        type: "link",
        icon: "Note_stack_add",
        label: "Additionals",
        to: "/Finance/AdditionalsInvoices",
      },
      {
        type: "link",
        icon: "StarFilled",
        label: "Invoices",
        to: "/Finance/InvoicesStatus",
      },
      {
        type: "link",
        icon: "StarFilled",
        label: "Payments",
        to: "/Finance/PaymentsStatus",
      },
    ],
  },
  "/Hr": {
    type: "App",
    active: false,
    label: "H.R.",
    description: "Human Resources and Pay workflows",
    icon: "Dashboard",
    to: "/Hr",
    tooltip: "HR Finance",
    pinned: false,
    menu: [
      {
        type: "link",
        icon: "Notifications",
        label: "Alerts",
        to: "/Finance/AlertsInvoices",
      },
      { type: "divider" },
      {
        type: "link",
        icon: "Note_stack_add",
        label: "Additionals",
        to: "/Finance/AdditionalsInvoices",
      },
    ],
  },
  "/Units": {
    type: "App",
    active: false,
    label: "Units",
    description: "Unit management",
    icon: "Dashboard",
    to: "/Units",
    tooltip: "Unit management",
    pinned: false,
    menu: [{ type: "link", icon: "Star", label: "Units", to: "/" }],
  },
  "/Search": {
    type: "App",
    active: false,
    label: "Search",
    description: "Search all of DCP",
    icon: "Search",
    to: "/Search",
    tooltip: "Search all of DCP",
    pinned: false,
    menu: [
      { type: "link", icon: "Search", label: "All", to: "/" },
      { type: "link", icon: "Search", label: "Clients", to: "/" },
      { type: "link", icon: "Search", label: "Carers", to: "/" },
      { type: "link", icon: "Search", label: "Contacts", to: "/" },
    ],
  },
  "/Alerts": {
    type: "App",
    active: false,
    label: "Alerts",
    description: "Alerts App",
    icon: "Notifications",
    to: "/Alerts",
    tooltip: "Alerts App",
    pinned: false,
    menu: [
      { type: "link", icon: "Search", label: "All", to: "/" },
      { type: "link", icon: "Search", label: "Clients", to: "/" },
      { type: "link", icon: "Search", label: "Clients EMARS", to: "/" },
      { type: "link", icon: "Search", label: "Carers", to: "/" },
      { type: "link", icon: "Search", label: "Scheduling", to: "/" },
      { type: "link", icon: "Search", label: "Finance", to: "/" },
    ],
  },
  "/Downloads": {
    type: "App",
    active: false,
    label: "Looker",
    description: "Downloaded reports",
    icon: "Star",
    to: "/Downloads",
    tooltip: "Download",
    pinned: false,
  },  "/Reports": {
    type: "App",
    active: false,
    label: "Looker",
    description: "Data Insights — opens Looker in a new tab",
    icon: "Star",
    to: "/Reports",
    tooltip: "Data Insights — opens Looker in a new tab",
    pinned: false,
  },
  "/Audit": {
    type: "App",
    active: false,
    label: "Audit",
    description: "Auditing App",
    icon: "Star",
    to: "/Audit",
    tooltip: "Auditing App",
    pinned: false,
    disabled: true,
  },
  "/Settings": {
    type: "App",
    active: false,
    label: "Settings",
    description: "DCP settings",
    icon: "Settings",
    to: "/Settings",
    tooltip: "DCP settings",
    pinned: false,
    disabled: true,
    menu: [
      {
        type: "link",
        label: "Status",
        icon: "Star",
        to: "/Status",
        tooltip: "Status and Diagnostics",
        subMenu: null,
      },
      {
        type: "link",
        label: "Admin",
        icon: "Settings",
        to: "/Admin",
        tooltip: "Admin",
        subMenu: null,
      },
      {
        type: "link",
        label: "Settings",
        icon: "Settings",
        to: "/Settings",
        tooltip: "DCP settings",
        subMenu: null,
      },
    ],
  },
  "/Apps": {
    type: "App",
    active: false,
    label: "More",
    description: null,
    icon: "apps",
    tooltip: <Icon>Apps</Icon>,
    to: "",
    gap: null,
    pinned: true,
  },
};
;




/**
 * This function generates a filtered "rail menu" with all pinned apps and a subMenu of unpinned oned on the Apps icon'.
 * It iterates through the 'appsMenu' object, categorizes its contents, and creates a modified menu
 * by separating pinned and unpinned items into submenus. The result, 'railMenuData', represents a
 * menu system with pinned items at the top level and unpinned items grouped under the '/Apps' submenu.
 *
 * @returns {Object} The 'railMenuData' object containing the filtered menu data.
 */
export function railMenu(appsMenuParam: AppsMenu) {
  let railMenuData: { [key: string]: any } = {};
  const appSubmenu: { [key: string]: any } = {};
  for (const keyAny in appsMenuParam) {
    const key = keyAny as appsMenuKeyType;
    const appApp: (typeof appsMenuParam['/Apps']) = appsMenuParam['/Apps'];
    const v = appsMenuParam[key];
    if (!v.pinned) { appSubmenu[key] = appsMenuParam[key]; }
  };
  appsMenuParam['/Apps'].subMenu = appSubmenu;
  for (const key in appsMenuParam) { const v = appsMenuParam[key as appsMenuKeyType]; if (v.pinned || v.active) railMenuData[key] = (appsMenuParam[key as appsMenuKeyType]); }
  return railMenuData;
}


export function makeMenuItemActive(appsMenuParam: AppsMenu, item: appsMenuKeyType) {
  for (let k in appsMenuParam) appsMenuParam[k].active = appsMenuParam[k].to == item;
}

let menuHierarchy = {
  '/': {
    label: 'DCP Home', back: null,
    menu: [
      { type: 'link', icon: 'Dashboard', label: 'Dashboard', to: '/' },
      { type: 'App', active: false, label: 'Inbox messages', icon: 'open_in_new', to: "/Inbox", tooltip: 'Intercom Inbox', subMenu: null, pinned: false, },
      { type: 'App', active: false, label: 'CeraNet', icon: 'open_in_new', to: "/CeraNet", tooltip: 'Cera Net', subMenu: null, pinned: false, },
      { type: 'App', active: false, label: 'Jarvis H&S', icon: 'open_in_new', to: "/Jarvis", tooltip: 'Downloaded documents', subMenu: null, pinned: false, disabled: true },
      { type: 'App', active: false, label: 'Looker reporting', icon: 'open_in_new', to: "/Jarvis", tooltip: 'Downloaded documents', subMenu: null, pinned: false, disabled: true },

    ],
  },

  '/Clients/Client': {
    label: 'Charles Appleseed',
    back: '/Clients',
    menu: [
      { type: 'link', icon: 'Dashboard', label: 'Clients', to: '/' },
      // {type:'link',icon:'Calendar_Today',label:'Calendar/Diary'},
      { type: 'divider' },

      { type: 'link', icon: 'Medication', label: 'EMARS', to: '/' },
      { type: 'link', icon: 'Library_Books', label: 'Care Plan', to: '/' },
      { type: 'divider' },
      { type: 'link', icon: 'Verified', label: 'Compliance', to: '/' },
      { type: 'link', icon: 'Fact_check', label: 'Tasks', to: '/' },
      { type: 'link', icon: 'auto_stories', label: 'Diary', to: '/' },
      { type: 'divider' },
      { type: 'link', icon: 'Heart_check', label: 'Preferences', to: '/' },
      { type: 'link', icon: 'Timer_off', label: 'Absences', to: '/' },
      { type: 'link', icon: 'Receipt_Long', label: 'Audit', to: '/' },
      { type: 'link', icon: 'Summarize', label: 'Reports', to: '/' },
    ],
  },


  "/Carers/Carer": {
    label: 'Carer',
    back: '/Carers',
    menu: [
      { type: 'link', icon: 'Person', label: 'Profile', to: '/' },
      { type: 'link', icon: 'Star', label: 'Visits', to: '/' },
      { type: 'link', icon: 'Verified', label: 'Compliance', to: '/' },
      { type: 'link', icon: 'Fact_check', label: 'Tasks', to: '/' },
      { type: 'divider' },
      { type: 'link', icon: 'Star', label: 'Availability', to: '/' },
      { type: 'divider' },
      { type: 'link', icon: 'Star', label: 'Payments', to: '/' },
      { type: 'link', icon: 'Star', label: 'Absences', to: '/' },
      { type: 'link', icon: 'Star', label: 'Accrual', to: '/' },
      { type: 'link', icon: 'Star', label: 'Pay advice', to: '/' },
      { type: 'divider' },
      { type: 'link', icon: 'Star', label: 'Documents', to: '/' },
      { type: 'link', icon: 'Star', label: 'Working preferences', to: '/' },
      { type: 'link', icon: 'Star', label: 'Checklists', to: '/' },
      { type: 'divider' },
      { type: 'link', icon: 'Star', label: 'CarePlanner Profile', to: '/' },
    ]
  },

  '/Inbox': {
    label: 'Inbox', back: null,
    menu: [
      { type: 'link', icon: 'Chat', label: 'Intercom', to: '/' },
    ]
  },

  '/Profile': {
    label: 'Profile', back: null,
    menu: [
      { type: 'link', icon: 'Dashboard', label: 'My activity', to: '/' },
      { type: 'link', icon: 'Person', label: 'My details', to: '/' },

      { type: 'link', icon: 'Chat', label: 'Intercom', to: '/' },
    ]
  },
};

export default menuHierarchy;


let menus = Object.keys(menuHierarchy);
for (const key in appsMenu) {
  const app = appsMenu[key];
  let n = {
    label: app.label, menu: app.menu, back: app.back
  };
  menuHierarchy[key as keyof typeof menuHierarchy] = n as any;
}


console.log(JSON.stringify(appsMenu))
console.log(menus)




