import acerTokens from './tokens/acerTokens';
const tokens = acerTokens.light;

const transitions = {
  easing: {
    easeInOut: tokens.transition.easeInOut.value,
    easeOut: tokens.transition.easeOut.value,
    easeIn: tokens.transition.easeIn.value,
    sharp: tokens.transition.sharp.value,
    none: '',
  },
  duration: {
    instant: tokens.duration.instant.value,
    shortest: tokens.duration.shortest.value,
    leavingScreen: tokens.duration.leavingScreen.value,
    shorter: tokens.duration.shorter.value,
    enteringScreen: tokens.duration.enteringScreen.value,
    short: tokens.duration.short.value,
    standard: tokens.duration.standard.value,
    complex: tokens.duration.complex.value,
    medium: tokens.duration.medium.value,
    slow: tokens.duration.slow.value,
    verySlow: tokens.duration.verySlow.value,
  },
};
export default transitions;