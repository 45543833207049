import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Chart from "../components/Chart";
import Deposits from "../components/Deposits";
import Orders from "../components/Orders";
import Footer from "../PageFooter/PageFooter";
import Search from "../components/Search";
import Tasks from "../components/Tasks";


export default function DemoPage() {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Tasks title="Carer tasks"/>
        <Grid container spacing={3}>
        
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Deposits />
            </Paper>
          </Grid>
          {/* Recent Orders */}

        </Grid>
        <Footer sx={{ pt: 4 }} />
      </Container>
    );
  }
  