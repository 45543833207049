import { Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import acerTokens from './tokens/acerTokens';
const tokens = acerTokens.light;

const FONT_WEIGHTS = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

const defaultFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';

const createTypographyOptions = (_?: Palette): TypographyOptions => typographyData();

export default createTypographyOptions;

export function typographyData(): TypographyOptions {
  let options = {} as any;
  Object.keys(tokens.typography).forEach((_key) => {
    const key = _key as keyof typeof tokens.typography;
    const style = tokens.typography[key].value;
    options[key] = style
  });
  return options;
}