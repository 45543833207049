import acer_common_colors from './acer_common_colors.json';
import acerTokens_dark from './acerTokens_dark.json';
import acerTokens_light from './acerTokens_light.json';

const acer_tokens = {
  colors:acer_common_colors,
  light: acerTokens_light,
  dark: acerTokens_dark,
};

export default acer_tokens;
