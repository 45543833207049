import * as React from "react";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { List, ListItem, Paper, Typography } from "@mui/material";


import Chart from "../components/Chart";
import Deposits from "../components/Deposits";
import Orders from "../components/Orders";
import Footer from "../PageFooter/PageFooter";


export default function DemoPage() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      These are wireframes for prototyping and testing — not UI designs.
      <List><ListItem><Link to="/clients">
       Start (use intercom to swap rail/topbar)
      </Link></ListItem>
      </List>
      <Footer sx={{ pt: 4 }} />
    </Container>
  );
}
