import * as React from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  makeStyles,
  Theme,
} from "@mui/material/styles";
import MuiAppBar, {
  AppBarProps as MuiAppBarProps,
  AppBarTypeMap,
} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { BrowserRouter as Router, Link } from "react-router-dom";

import CeraBrandIcon from "@mui/icons-material/AddBox";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import MessagesIcon from "@mui/icons-material/Message";
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import createDcpUiTheme from "../theme/createTheme";

const theme = createDcpUiTheme();

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const TopBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

function idProp(index: number) {
  return {
    id: `main-tab-${index}`,
    value: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  //
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "red",
  },
});

function BrandBranch() {
  return (
    <React.Fragment>
      <Link to="/Home" color={theme.palette.textLight.contrast}>
        <CeraBrandIcon
          fontSize="large"
          sx={{ color: theme.palette.textLight.contrast }}
        />
      </Link>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          labelId="demo-main-select-label"
          id="demo-main-select"
          value={20}
          sx={{
            fontSize: 12,
            color: "#fff",
            "& MuiSelect-root": { fontSize: 12 },
            "& .MuiSvgIcon-root": { color: "white" },
          }}
          // onChange={handleChange}
        >
          <MenuItem value={10}>Wandsworth</MenuItem>
          <MenuItem value={20}>Hammersmith</MenuItem>
          <MenuItem value={30}>Shepherd's Bush</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

function BasicTabs() {
  const [value, setValue] = React.useState(0);
  //if (!value)
  console.log(value);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", color: "text.light.contrast" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        TabIndicatorProps={{ sx: { backgroundColor: "#ffffffff", height: 4 } }}
        textColor="inherit"
        aria-label="Main rop menu"
        sx={{
          height: 64,
          color: theme.palette.textLight.contrast,
          "& a.MuiTab-textColorInherit ": {
            color: "#fff",
            fontWeight: 500,
            fontSize: 16.5,
            opacity: 0.75,
          },
          "& a.MuiTab-textColorInherit.Mui-selected": {
            color: "#fff",
            fontWeight: 500,
            fontSize: 16.5,
            opacity: 1,
          },
        }}
      >
        <Tab icon={<BrandBranch/>} {...idProp(10)} component={Link} to={"/Home"} />
        <Tab
          label="Service Users"
          {...idProp(20)}
          component={Link}
          to={"/Clients"}
          sx={{ color: "#fff", height: 64, ml: 8 }}
        />
        <Tab label="Roster" {...idProp(30)} component={Link} to={"/Visits"} />
        <Tab label="Carers" {...idProp(40)} component={Link} to={"/Carers"} />
        <Tab
          label="Finance"
          {...idProp(50)}
          component={Link}
          to={"/Finance"}
          sx={{ height: 64, ml: 8, mr: "auto" }}
        />
        <Tab
          label={
            <Badge badgeContent={4} color="error">
              &#160;&#160;inbox&#160;&#160;
            </Badge>
          }
          {...idProp(60)}
          component={Link}
          to={"/Inbox"}
        />
        <Tab
          label={
            <Badge badgeContent={4} color="error">
              &#160;&#160;Tasks&#160;&#160;
            </Badge>
          }
          {...idProp(70)}
          component={Link}
          to={"/Alerts"}
        />
        <Tab
          icon={<SearchIcon />}
          style={{ width: 48, minWidth: 48 }}
          {...idProp(80)}
          component={Link}
          to={"/Search"}
        />
        <Tab
          icon={<Avatar />}
          style={{ width: 48, minWidth: 48 }}
          {...idProp(90)}
          component={Link}
          to={"/Profile"}
        />
      </Tabs>
    </Box>
  );
}

const styles = (theme: any) => ({
  formControl: {
    margin: theme.palette.text.light.contrast,
  },
  whiteLabel: {
    color: "#0f0",
  },
  whiteUnderline: {
    "&:hover:not($disabled):before": {
      backgroundColor: "#040",
    },
  },
  whiteInkbar: {
    "&:after": {
      backgroundColor: "#0f0",
    },
  },
});

function AppBarToolBar() {
  const [open, setOpen] = React.useState(true),
    [value, handleChange] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <TopBar position="absolute">
      <Toolbar>
        <BasicTabs />
      </Toolbar>
    </TopBar>
  );
}

export default AppBarToolBar;
