

import React, { useContext } from "react";
import Container from "@mui/material/Container";
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem ,{MenuItemProps} from '@mui/material/MenuItem';

import { layoutContext } from '../../App/App';

export default function HomePage() {
  const layout = useContext(layoutContext);
  const setRail = () => { layout.setLayoutMode('rail') }
  const setTop = () => { layout.setLayoutMode('topBar') }

  return (
    <Container sx={{}}>
      <Grid sx={{padding:4}} spacing={2}>
        <Chip label="rail" variant="outlined" onClick={setRail}></Chip>&#160;&#160;&#160;
        <Chip label="topBar" variant="outlined" onClick={setTop}></Chip>
      </Grid>
      <Grid sx={{padding:4}}>
      <TextField
          id="outlined-select-currency"
          select
          label="Select"
          defaultValue="0"
          helperText="Please select your currency"
        >
          {[{value:0, label:'Kingston-on-Thames'},{value:1, label:'Walton-on-Thames'}, {value:-1,label:'Request access to another branch' }].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <img src="screenShots/dashboard.png" width="100%" />
    </Container>
  );
}
