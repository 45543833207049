import { Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const createComponentsOverrides = (palette: Palette, _: TypographyOptions) => ({
  Icon:{
    baseClassName:"material-symbols-rounded" ,
  },
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: palette.primary.mainHover,
        },
      },
      containedsecondary: {
        '&:hover': {
          backgroundColor: palette.secondary.mainHover,
        },
      },
      outlinedPrimary: {
        backgroundColor: palette.primary.outlinedResting,
        '&:hover': {
          backgroundColor: palette.primary.outlinedHover,
        },
      },
      outlinedSecondary: {
        backgroundColor: palette.secondary.outlinedResting,
        '&:hover': {
          backgroundColor: palette.secondary.outlinedHover,
        },
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: palette.primary.outlinedHover,
        },
      },
      textSecondary: {
        '&:hover': {
          backgroundColor: palette.secondary.outlinedHover,
        },
      },
    },
  },
});

export default createComponentsOverrides;


