import { createTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import createTypography from '@mui/material/styles/createTypography';
//
import createComponentsOverrides from './createComponentsOverrides';
import createPaletteOptions from './createPaletteOptions';
import transitions from './createTransitions';
import createTypographyOptions from './createTypographyOptions';

const palette = createPalette(createPaletteOptions());
const typography = createTypographyOptions(palette);

// The Palette, typography and transitions are taken from the tokes in AcerTokens.json
// acerTokens.json is processed by tokens.sh to replace references to other tokens with values
// and then incorporated into a variable 
// import acerTokens from '../tokens/acerTokens';

const createDcpUiTheme = () =>
  createTheme({
    palette,
    typography: createTypography(palette, typography),
    components: createComponentsOverrides(palette, typography),
    transitions,
  });

export default createDcpUiTheme;
